*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html{
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}


ul,li{
  list-style: none;
}
a{
  color: inherit;
  text-decoration: none;
}
@font-face {
	font-family: 'Pretendard Variable';
	font-weight: 45 920;
	font-style: normal;
	font-display: swap;
	src: url('./woff2/PretendardVariable.woff2') format('woff2-variations');
}

button[type="submit"],
input{
	font-family: 'Pretendard Variable';
}
input:focus{
  outline: none !important;
  border: 1px solid var(--primary-700) !important;
}
:root{
	font-family: 'Pretendard Variable';
  font-size: 62.5%;

  --grayscale-900: #212121 ;
  --grayscale-700: #343434 ;
  --grayscale-600: #666666 ;
  --grayscale-500: #A5A5A5 ;
  --grayscale-400: #C4C4C4 ;
  --grayscale-300: #EEEEEE ;
  --grayscale-200: #F1F1F1 ;
  --grayscale-100: #F7F7F7 ;

  --primary-700 : #6B5FFF;
  --primary-200 : #EAE9FF;
  --primary-100 : #F8F7FF;

  --secondary-yellow : #FBB750;
  --secondary-red : #ED3B66;
  --secondary-navy : #0F3472;

  --gradient_01: linear-gradient(269.69deg, #FF85C0 10.24%, #D488F7 34.58%, #76F7FF 108.15%);
  ;
}
.headline01{
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -2%;
}
.headline02{
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -2%;
}
.sub_headline01{
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -4%;
}
.sub_headline02{
  font-size: 2rem;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -4%;
}
.large01{
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -1%;
}
.large02{
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -2%;
}
.large03{
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -2%;
}
.small01{
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -2%;
}
.small02{
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -2%;
}
.label01{
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -4%;
}
.label02{
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -4%;
}
.caption{
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -2%;
}

.flex{
  display: flex;
}
.al_ce{
  align-items: center;
}
.al_fs{
  align-items: flex-start;
}
.al_fe{
  align-items: flex-end;
}
.ju_fe{
  justify-content: flex-end;
}
.ju_sb{
  justify-content: space-between;
}
.ju_sa{
  justify-content: space-around;
}
.ju_ce{
  justify-content: center;
}
.fd_co{
  flex-direction: column;
}
.primary_btn{
  display: block;
  color: var(--primary-700);
  border: 1px solid var(--primary-700);
  border-radius: 1.2rem;
  width: 100%;
  text-align: center;
  padding: 1rem;
}
.primary_fill_btn{
  display: block;
  color: #fff;
  background-color: var(--primary-700);
  border: 1px solid var(--primary-700);
  border-radius: 1.2rem;
  width: 100%;
  text-align: center;
  padding: 1rem;
  animation: btn_actiavte 100ms cubic-bezier(0.42, 0, 0.58, 1 );
  transition: 0.1s;
}
.primary_fill_btn.pressed{
  transform: scale(0.9);
  background-color: #4135CC;
}
@keyframes btn_actiavte {
  0%{
    opacity: 0;
  } 
  100%{
    opacity: 1;
  }
}
.gray_btn{
  display: block;
  color: #fff;
  background-color: var(--grayscale-400);
  border: 1px solid var(--grayscale-400);
  border-radius: 1.2rem;
  width: 100%;
  text-align: center;
  padding: 1rem;
}
.gray_line_btn{
  display: block;
  background-color: white;
  border: 1px solid var(--grayscale-400);
  border-radius: 1.2rem;
  width: 100%;
  text-align: center;
  padding: 1rem;
}
.p200_btn{
  display: block;
  color: var(--primary-700);
  background-color: var(--primary-200);
  border: 1px solid var(--primary-200);
  border-radius: 1.2rem;
  width: 100%;
  font-weight: 700;
  text-align: center;
  padding: 1rem;
}
.gray_btn.p200{
  display: block;
  color: #fff;
  background-color: var(--grayscale-400);
  border: 1px solid var(--grayscale-400);
  border-radius: 1.2rem;
  width: 100%;
  text-align: center;
  padding: 1rem;
}
button.gray_btn{
  color: #fff;
  background-color: var(--grayscale-400);
  border: none;
  padding: 2rem;
  border-radius: 1.2rem;
  text-align: center;
}
button.primary_btn{
  color: #fff;
  background-color: var(--primary-700);
  font-weight: 700;
  text-align: center;
}
/* form_type_01 */
.form_type_01 .lable_box > label{
  display: block;
  margin-bottom: 1.4rem;
}
.form_type_01 input::placeholder{
  color: var(--grayscale-400);
}
.form_type_01 input,
.form_type_01 .radio > label{
  display: block;
  width: 100%;
  font-size: 1.6rem;
  padding: 1.6rem;
  border: 1px solid var(--grayscale-400) ;
  border-radius: 1.2rem;
  position: relative;
}
.form_type_01 .radio {
  position: relative;
  width: 100%;
  text-align: center;
}
.form_type_01 .radio > input[type="radio"]{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  opacity: 0;
}
.form_type_01 .radio > input[type="radio"]:checked + label{
  color: var(--primary-700);
  border: 1px solid var(--primary-700);
  background-color: var(--primary-100);
}
/* custom */
.App{
  min-width: 360px;
  min-height: 100vh;
  margin: 0 auto;
}
.sub_body_wrap{
  padding-bottom: 5rem;
}
@media screen and (max-width:1920px) {
  :root{
    font-size: 2.7778vw;
  }
}
@media screen and (max-width:360px) {
  :root{
    font-size: 10px;
  }
}
.App .con{
  padding-right: 2rem;
  padding-left: 2rem;
  overflow: hidden;
}

.main_visual{
  position: relative;
  margin-bottom: 12.3rem ;
}

.main_visual .visual_img{
  position: relative;
}
.main_visual .visual_img > video{
  width: 100%;
}
.main_visual .visual_img > .overlay{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
  backdrop-filter: blur(0px);
  transition: 0.5s;
}
.main_visual .visual_img > .overlay.blur{
  backdrop-filter: blur(7.5px);
}


.visual_text{
  position: absolute;
  width: 100%;
  top: 15rem;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  color: var(--secondary-navy);
}
.visual_text > *{
  transform: translateY(100px);
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
}
.main_visual.active .btn_gradient,
.main_visual.active .headline02,
.main_visual.active .small02{
  transform: translateY(0);
  opacity: 1;
}
.visual_text > .headline02{
  margin-bottom: 3rem;
  transition-delay: 0.5s ;
}
.visual_text > .small02{
  margin-bottom:5rem;
  transition-delay: 1s;
}
.btn_gradient{
  display: block;
  transition-delay: 1.5s;
  margin-bottom: 6.4rem;
}
.btn_gradient > img{
  width: 27.4rem;
  display: block;
  margin: 0 auto;
}

.scroll_nav.active{
  opacity: 1;
}
.scroll_nav{
  width: 100%;
  text-align: center;
  color: var(--grayscale-600);
  opacity: 0;
  transition: opacity 0.5s;
  transition-delay: 2s;
  transform: translateY(0) !important;
}
.scroll_nav .small02{
  line-height: 1rem;
}
.scroll_nav .label02{
  margin-top: 1.6rem;
  color: var(--grayscale-400);
  line-height: 1rem;
}
.scroll_arrow{
  width: 3.6rem;
  margin-bottom: 1.6rem;
}
.about_wrap{
  border-top: 1px solid var(--grayscale-200);
  padding-top: 3rem;
  border-bottom: 1px solid var(--grayscale-200);
  padding-bottom: 5rem;
}
.about_wrap .text_box{
  margin-top: 5rem;
  margin-bottom: 2.4rem;
}
.about_wrap .text_box > .sub_headline02{
  color: var(--grayscale-900);
  margin-bottom: 2rem;
}
.about_wrap .text_box > .small02{
  color: var(--grayscale-700);
}
.about_lulu{
  width: 100%;
  margin-bottom: 2rem;
}
.survey_wrap{
  border-bottom: 1px solid var(--grayscale-200);
}
.camera_slide_wrap .slide_img{
  display: block;
  margin: 0 auto;
  width: 30.4rem;
}
.survey_wrap .slide_img{
  display: block;
  margin: 0 auto;
  width: 20rem;
}
.survey_box{
  padding-top: 4rem;
  padding-bottom: 5.4rem;
}
.survey_wrap .box_label{
  color: var(--primary-700);
  background-color:var(--primary-200);
  padding: 0.6rem 1rem;
  display: inline-block;
  border-radius: 0.5rem ;
  margin-bottom: 2.4rem;
}
.survey_wrap .sub_headline01{
  margin-bottom: 2.6rem;
}
.survey_wrap .sub_headline01 span{
  color: var(--primary-700);
  position: relative;
  opacity: 0;
  transition: 0.2s;
}
.survey_wrap .sub_headline01 span.on{
  opacity: 1;
}
.survey_wrap .sub_headline01 span::after{
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: var(--primary-700);
}
.survey_wrap .primary_fill_btn{
  margin-top: 7rem;
}
.product_box .slick-dots li,
.survey_wrap .slick-dots li{
  width: 0.6rem;
  height: 0.6rem;
}
.product_box .slick-dots li button:before,
.camera_slide_wrap .slick-dots li button:before,
.survey_wrap .slick-dots li button:before{
  opacity: 1;
  color: var(--grayscale-300);
}
.camera_slide_wrap .slick-dots li.slick-active button:before,
.survey_wrap .slick-dots li.slick-active button:before{
  opacity: 1;
  color: var(--primary-700);
}
.survey_wrap .caption{
  margin-top: 2.4rem;
  color: var(--grayscale-500);
}
.share_wrap{
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: var(--grayscale-500);
  text-align: center;
}
.share_icon_wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  margin-top: 3rem;
}
.share_icon_wrap img{
  width: 5.6rem;
}
.sub_page{
  height: 100vh;
  overflow: hidden;
}
.sub_page.over{
  overflow: visible;
}
.over.sub_page .sub_body_wrap{
  height: 100%;
}
.sub_page.gray{
  background:var(--primary-100);
}
.sub_page .sub_top_bar{
  text-align: center;
  justify-content: space-between;
  height: 5.6rem ;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}
.sub_page .sub_top_bar .sub_page_top_title{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.sub_page .sub_top_bar .back_btn{
  width: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_page .sub_top_bar .back_btn>img{
}
.sub_page .sub_top_bar .home_btn{
  width: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sub_page .sub_body_wrap{
  overflow: scroll;
  height: 100%;
  min-height: calc(100vh - 5.6rem);
}
.sub_page .sub_body_wrap .sub_body{
  min-height: 100%;
  height: auto;
  padding-top: 2rem;
}
.sub_page .sub_body_wrap .sub_body .sub_title .sub_headline02{
  color: var(--grayscale-700);
  margin-bottom: 1rem;
}
.sub_page .sub_body_wrap .sub_body .sub_title .small02{
  color: var(--grayscale-600);
}
.info_wrap{
  margin-top: 4rem;
}
.info_wrap > .form{
  display: flex;
  gap: 3rem;
  flex-direction: column;
}
.radio_wrap {
  gap: 1.6rem ;
}
.check {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.check > input{
  width: auto;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
}
.check label{
  display: flex !important;
  margin-bottom: 1.4rem;
  margin-top: 1.4rem;
}
.check label .icon_box img{
  width: 2.4rem;
}
.check label .icon_box img:nth-child(1){
  display: block;
}
.check label .icon_box img:nth-child(2){
  display: none;
}
.check input:checked + label .icon_box  img:nth-child(1){
  display: none;
}
.check input:checked + label .icon_box  img:nth-child(2){
  display: block;
}
.check .icon_box{
  margin-right: 1.3rem ;
}
.all_agree{
  border-bottom: 1px solid var(--grayscale-300);
}
input[type="date"]::placeholder{
  content: attr(placeholder);
}
.check .arrow_box{
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.camera_slide_wrap .slick-slider {
  margin-bottom: 9.6rem;
} 
.camera_slide_wrap .btn_wrap > div{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.camera_slide_wrap .btn_wrap > div:first-child{
  display: flex;
}
.camera_slide_wrap .btn_wrap.active > div:first-child{
  display: none;
}
.camera_slide_wrap .btn_wrap > div:last-child{
  display: none;
}
.camera_slide_wrap .btn_wrap.active > div:last-child{
  display: flex;
}
.progress_box{
  width: 20rem;
  height: 0.6rem;
  background-color: var(--grayscale-200);
  border-radius: 0.3rem;
  margin: 0 auto;
}
.loading_wrap{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
}
.survey_loading{
  width: 32rem;
  height: 0.6rem;
  background-color: var(--grayscale-200);
  border-radius: 0.3rem;
  margin: 0 auto;
}
.progress_bar{
  display: block;
  min-width: 0.6rem;
  height: 100%;
  background-color: var(--primary-700);
  border-radius: 0.3rem;
  transition: 0.5s;
}
.survey_form_wrap{
  margin-top: 4rem;
}
.survey_form_wrap .survey_num{
  color: var(--grayscale-500);
  text-align: center;
  margin-bottom: 2.4rem;
}
.survey_form_wrap .survey_title{
  text-align: center;
  margin-bottom: 3rem ;
  min-height: 5.6rem;
}
.survey_radio_wrap{
  min-height: 20.9rem;
}
.survey_form_wrap .survey_radio{
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.survey_form_wrap .survey_radio input{
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
}
.survey_form_wrap .survey_radio label{
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  border: 1px solid var(--grayscale-300);
  border-radius: 1.2rem;
}
.survey_form_wrap .survey_radio input + label .check_box{
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 0.9rem;
  border: 1px solid var(--grayscale-400);
} 
.survey_form_wrap .survey_radio input:checked + label {
  background-color: var(--primary-100);
  border: 1px solid var(--primary-700);
}
.survey_form_wrap .survey_radio input:checked + label .check_box{
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 0.9rem;
  border: 1px solid var(--primary-700);
  position: relative;
} 
.survey_form_wrap .survey_radio input:checked + label .check_box::after{
  content: "";
  display: block;
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color:  var(--primary-700);
} 
.transition_desolve{
  opacity: 1;
  transition: 0.5s 0.5s;
}
.transition_desolve.active{
  opacity: 0;
}
.result_capture_area .luluti_info{
  color: var(--grayscale-600) ;
  position: relative;
  gap: 0.2rem;
}
.result_capture_area .luluti_info .home_btn{
  position: absolute;
  right: 0;
}
.result_capture_area .type_title{
  text-align: center;
  margin-top: 3rem;
}
.result_capture_area .type_title .headline01{
  color: var(--primary-700) ;
  line-height: 1;
}
.result_capture_area .type_title .sub_headline02{
  color: var(--grayscale-900) ;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  line-height: 1;
}
.result_capture_area .type_title .small02{
  color: var(--grayscale-500) ;
  line-height: 1;
  display: flex;
  justify-content: center;
  gap: 0.8rem;
}
.result_capture_area .type_img{
  margin-top: 2.9rem;
  padding-right: 5.5rem;
  padding-left: 5.5rem;
}
.result_capture_area .type_img > img{
  width: 100%;
}
.result_capture_area .type_info{
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 4.9rem;
}
.result_capture_area .type_info > span{
  color: var(--primary-700);
}
.gray_line{
  width: 100%;
  height: 1px;
  background-color: var(--grayscale-200);
}
.gray_line.thick{
  height: 0.8rem ;
  width: calc(100% + 4rem);
  margin-left: -2rem;
}

.balance {
  margin-top: 4rem;
  margin-bottom: 6rem;
}
.balance .balance_bar{
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  width: 100%;
  height: 0.8rem;
  border-radius: 0.1rem;
  background: linear-gradient(90deg, #E1C4A3 7.97%, #71D8FC 50.26%, #FFE76A 91.72%);
  position: relative;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}
.balance .balance_circle{
  width: 1.8rem;
  height: 1.8rem;
  background-color: white;
  border: 0.5rem solid var(--primary-700) ;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);
  box-sizing: border-box;
}
.balance .text_box .dry{
  text-align: left;
  line-height: 1;
}
.balance .text_box .neutral{
  text-align: center;
  line-height: 1;
}
.balance .text_box .oily{
  text-align: right;
  line-height: 1;
}
.balance .text_box > div > .small02{
  color: var(--grayscale-600);
  margin-bottom: 0.6rem;
}
.balance .text_box > div > .lable02{
  color: var(--grayscale-400);
}

.result_type_wrap{
  margin-bottom: 4rem;
}
.result_type_wrap .result_type_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem ;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  margin-top: 1.8rem ;
}
.result_type_wrap .result_type_box .result_type{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: center;
  justify-self: center;
  padding: 1rem;
  border: 1px solid var(--grayscale-300);
  border-radius: 0.8rem;
}
.result_type_wrap .result_type_box .result_type .ko{
  color: var(--grayscale-500);
  text-align: center;
  /* Body/small 2, medium */
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 1;
}
.result_type_wrap .result_type_box .result_type .en{
  color: var(--grayscale-400);
  text-align: center;
  /* Label/label 2, medium */
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.56px;
  line-height: 1;
}
.result_type_wrap .result_type_box .result_type.on{
  border: 1px solid var(--primary-700);
  background: rgba(107, 95, 255, 0.05);;
}
.result_type_wrap .result_type_box .result_type.on .ko{
  color: var(--primary-700);
  font-weight: 700;
}
.capture_btn{
  margin-top: 6rem;
  display: flex;
  gap: 1rem;
  margin-bottom: 5.6rem ;
}
.similar_type{
  margin-top: 6rem;
  margin-bottom: 5.6rem;
}
.similar_type .title{
  text-align: center;
  margin-bottom: 4rem;
}
.similar_type .title > span{
  color: var(--primary-700);
}
.similar_type .slick-slider{
  padding-right: 3.5rem;
  padding-left: 3.5rem;
  margin-bottom: 7.6rem ;
}
.similar_type .slide_img{
  width: 100%;
}
.similar_type .similarSlide{
  text-align: center;
}
.similar_type .similarSlide .text_box{
  text-align: center;
  margin-top: 3rem;
}
.similar_type .similarSlide .text_box .sub_headline02{
  line-height: 1;
  color: var(--grayscale-900);
}
.similar_type .similarSlide .text_box .large03{
  color: var(--grayscale-600);
  line-height: 1;
  margin: 1.6rem 0;
}
.similar_type .similarSlide .text_box .small02{
  line-height: 1;
  color: var(--grayscale-500);
}
.similar_type .slick-dots{
  left: 0;
  bottom: -4rem;
}
.similar_type .slick-dots li{
  margin: 0;
}
.similar_type .slick-dots li.slick-active button:before{
  color: var(--primary-700);
  opacity: 1;
}

.btm_share.share_btn{
  margin-top: 3rem;
}
.btm_share.share_btn,
.similar_type .share_btn{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--grayscale-600);
}
.skin_problem_wrap{
  width: 100%;
  position: relative;
}
.skin_problem_wrap .title{
  margin-top: 5.6rem;
  text-align: center;
}
.skin_problem_wrap .title span{
  color: var(--primary-700);
}
.skin_concern_box{
  padding: 0 0.5rem;
}
.skin_concern{
  text-align: center;
  padding-top: 1rem  ;
  padding-bottom: 1.6rem  ;
  border: 1px solid var(--grayscale-200);
  border-radius: 0.8rem;
}
.active .skin_concern{
  box-shadow: 0px 2px 12px 0px rgba(107, 95, 255, 0.15);
  border: 1px solid var(--Primary-p700, #6B5FFF);
  backdrop-filter: blur(6px);
}
.skin_concern .slide_img{
  width: 3rem;
  margin: 0 auto; 
}
.skin_problem_wrap .slick-list{
  overflow: visible;
}
.skin_problem_wrap .slick-slide{
}
.slider_wrap{
  position: relative;
  width: 100%;
  height: 10rem;
  margin-top: 3.9rem ;
}
.slider_absolute{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.skin_concern .text_box > .label02:nth-child(2) {
  color: var(--primary-700);
}
.skin_problem_wrap > .label02{
  color: var(--grayscale-500);
  line-height: 1rem;
  margin-top: 2.7rem;
  margin-bottom: 3.6rem;
  text-align: center;
}
.skin_analyzed_img {
  padding: 0 1rem;
  position: relative;
}
.skin_analyzed_img > img{
  width: 100%;
}
.skin_analyzed_img >.skin_label{
  line-height: 1rem;
  padding:  0.8rem;
  background-color: white;
  border-radius: 0.5rem;
  color: var(--primary-700);
  position: absolute;
  left: 50%;
  top: 1.2rem;
  transform: translateX(-50%);
}
.recom_product_tap_box{
  margin-top: 3rem;
}
.recom_product_tap_box > .tap_menu{
  width: 100%;
  text-align: center;
  padding: 1.45rem 0 ;
  border-bottom: 1px solid var(--grayscale-100);
  color: var(--grayscale-600);
}
.recom_product_tap_box > .tap_menu.active{
  border-bottom: 2px solid var(--primary-700);
  color: var(--primary-700);
}
.recom_product_box {
  margin-top: 4rem;
  margin-bottom: 7.6rem;
  opacity: 0;
  transition: opacity 0.5s;
}
.recom_product_box.active{
  opacity: 1;
}
.recom_product_box .title > span {
  color: var(--primary-700);
  position: relative;
  opacity: 0;
}
.recom_product_box .title > span.active{
  opacity: 1;
  transition: 0.5s;
}
.recom_product_box .title > span::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--primary-700);
  bottom: 0;
  left: 0;
}
.recom_product_box .product_type_wrap{
  display: flex;
  flex-wrap: wrap;
  gap: 0.6rem;
  margin-top: 2.6rem;
  margin-bottom: 2rem;
}
.recom_product_box .product_type_wrap .product_type{
  padding: 1rem 1.2rem; 
  border-radius: 5rem ;
  border: 1px solid var(--grayscale-300);
  color: var(--grayscale-600);
}
.recom_product_box .product_type_wrap .product_type.active{
  color: var(--primary-700);
  border: 1px solid var(--primary-700);
}
.recom_product_box .product_box{
  padding: 0 4.5rem;
  width: 100%;
  margin: 0 auto;
  opacity: 0;
}
.product_slide{
  padding: 0 1.5rem;
}
.product_box .slick-list{
  overflow: visible;
}
.recom_product_box .product_box.active{
  opacity: 1;
  transition: 0.5s;
}
.recom_product_box .product_box .product_img{
  margin-bottom: 1.6rem;
}
.recom_product_box .product_box .product_img img{
  width: 100%;
}
.recom_product_box .product_box .product_brand{
  color: var(--grayscale-600);
  line-height: 1rem;
}
.recom_product_box .product_box .product_name{
  color: var(--grayscale-900);
  line-height: 1rem;
  margin-top: 1.4rem;
  margin-bottom: 1.8rem;
}
.recom_product_box .product_box .product_price{
  color: var(--primary-700);
  line-height: 1rem;
}
.recom_product_box .product_box .product_price::after{
  content: "원";
  font-size: 1.6rem;
}
.skin_rank {
  margin-top: 5.6rem;
  position: relative;
}
.skin_rank .popper{
  width: 100%;
  position: absolute;
  bottom: 0;
}
.skin_rank .popper img{
  width: 100%;
}
.skin_rank .title{
  text-align: center;
}
.skin_rank .rank_text{
  text-align: center;
  color: var(--primary-700);
  margin-top: 3rem;
  line-height: 1rem;
  margin-bottom: 2.4rem;
}
.skin_rank .rank_img {
  padding: 0 8.5rem;
}
.skin_rank .rank_img > img{
  width: 100%;
}
.skin_rank .rank_score{
  margin-top: 3.6rem;
  margin-bottom: 4rem;
  text-align: center;
}
.skin_rank .rank_score > .score{
  color: var(--primary-700);
}
.skin_rank .score_rating{
  text-align: center;
  font-weight: 500;
}
.score_rating{
  position: relative;
}
.score_rating::after{
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: var(--grayscale-300);
}
.score_rating .small02{
  color: var(--grayscale-600);
  margin-bottom: 1.4rem;
  line-height: 1rem;
}
.rank_bar_wrap{
  margin-top: 7.3rem;
  margin-bottom: 4rem;
}
.rank_bar_wrap .rank_bar{
  width: 100%;
  height: 0.8rem;
  border-radius: 0.4rem;
  background-color: var(--primary-200);
}
.rank_bar_wrap .rank_bar > .bar{
  position: relative;
  border-radius: 0.4rem;
  height: 100%;
  background: linear-gradient(-90deg, #6B5FFF -45.67%, #D6D3FF 100%), #FFF;;
  transition: 0.1s;
}
.rank_bar_wrap .rank_bar > .bar .remain{
  position: absolute;
  white-space: nowrap;
  font-size: 1.4rem;
  color: var(--grayscale-600);
  padding: 1rem 1.2rem;
  box-shadow: 0px 1.945px 9.727px 0px rgba(107, 95, 255, 0.15);
  bottom: 1.5rem;
  opacity: 0;
  transition: cubic-bezier(0.42, 0, 0.58, 1) 0.3s;
}
.rank_bar_wrap .rank_bar > .bar .remain.active{
  opacity: 1;
}
.rank_bar_wrap .rank_bar > .bar .arrow{
  position: absolute;
  right: 0;
  bottom: 100%;
  display: block;
  border-top: 0.9rem solid white;
  border-right: 0.45rem solid transparent;
  border-left: 0.45rem solid transparent;
}
.rank_table{
  margin-top: 0.8rem ;
  color: var(--grayscale-500);
}
.rank_table > div:last-child{
  color: var(--grayscale-600);
}
.influencer_pick{
  margin-top: 5.6rem;
  margin-bottom: 8.6rem;
}
.influencer_pick .title{
  text-align: center;
  margin-bottom: 4rem;
}
.InfluencerPickSlide .influencer_info{
  background-color: var(--grayscale-100);
  padding: 1.6rem;
  border-radius: 1.2rem 1.2rem 0px 0px;
  border: 1px solid var(--grayscale-200);
}
.InfluencerPickSlide .influencer_info .profile_img{
  border-radius: 50%;
  overflow: hidden;
  width: 5rem;
}
.InfluencerPickSlide .influencer_info .profile_img > img{
  width: 100%;
}
.InfluencerPickSlide .influencer_info .left{
  gap: 1.2rem ;
}
.InfluencerPickSlide .influencer_info .right{
  gap: 0.8rem ;
}
.InfluencerPickSlide .skin_type{
  color:var(--grayscale-500);
}
.InfluencerPickSlide .product_info{
  gap:1.6rem;
  padding: 1.6rem 1.6rem 3rem 1.6rem;
  border-radius: 0px 0px 1.2rem 1.2rem;
  border: 1px solid var(--grayscale-200) ;
  border-top: none;
}
.InfluencerPickSlide .product_info .product_brand{
  margin-top: 1.4rem;
  color: var(--grayscale-600);
  line-height: 0.8rem;
}
.InfluencerPickSlide .product_info .product_name{
  color: var(--grayscale-900);
  margin-top: 1.2rem;
  margin-bottom: 1.6rem;
  line-height: 1.55rem;
}
.InfluencerPickSlide .product_info .product_price{
  color: var(--primary-700);
  line-height: 1rem;
}
.influencer_pick .slick-dots li.slick-active button:before{
  color: var(--primary-700);
  opacity: 1;
}
.product_box .slick-dots{
  bottom: -3.5rem;
}
.product_box .slick-dots li.slick-active button:before{
  color: var(--primary-700);
  opacity: 1;
}
.influencer_pick .slick-dots li{
  margin: 0;
} 
.influencer_pick  .slick-dots{
  bottom: -2.4rem;
  transform: translateY(100%);
}
.mail_share_btn{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.3rem ;
  margin-bottom: 0.8rem;
}
.share_wrap .btn_box{
  margin-bottom: 4.2rem;
}
.btn_fixed{
  position: fixed;
  width: 100%;
  bottom: 2.4rem;
  padding: 0 2rem;
  left: 50%;
  transform: translateX(-50%);
}
.invalid{
  position: relative;
  padding-bottom: 2.3rem;
}
.invalid input {
  border-color: #ED3B66 !important;
}
.invalid::after{
  content: "올바르지 않은 형식 입니다.";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #ED3B66;
  font-size: 1.6rem;
  font-weight: 500;
}
.email_input.invalid::after{
  content: "올바르지 않은 이메일 주소에요.";
}
.name_input.invalid::after{
  content: "이름을 입력해 주세요.";
}
.birth_input.invalid::after{
  content: "생년월일을 입력해 주세요.";
}
.success_content{
  text-align: center;
}

.App .slick-dots li button:before {
  font-size: 0.6rem;
  line-height: 2rem;
  width: 2rem;
  height: 2rem;
}
.App .slick-dots li{
  margin: 0 0.5rem;
}
.insta_link > img ,
.youtube_link > img{
  width:4.4rem;
}
.detail_wrap{
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem ;
}
.detail_wrap > .detail_box{
  width: calc(50% - 0.6rem);
  padding: 1.6rem 0.7rem 2rem 0.7rem;
  background-color: white;
  border-radius: 1.5rem;
  text-align: center;
  cursor: pointer;
}
.detail_wrap > .detail_box > img{
  width: 100%;
}
.detail_wrap > .detail_box .small01{
  margin-top: 2rem ;
  margin-bottom: 1.4rem;
  line-height: 1;
}
.detail_wrap > .detail_box .label02{
  line-height: 1;
}
.fixed_btn_wrap{
  position: fixed;
  z-index: 99;
  width: 100%;
  bottom: 0;
  height: 9rem;
  background-color: #fff;
  padding: 1.4rem 2rem 2.4rem 2rem;
  pointer-events: none;
}
.fixed_btn_wrap > .btn_fixed{
  pointer-events:all;
}
.fixed_btn_wrap::after{
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0;
  height: 5rem;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
  pointer-events: none;
}
.fixed_btn_wrap + .sub_body_wrap{
  padding-bottom: 10rem;
}
.modal_page{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  z-index: 9999;
  background: white;
  overflow: scroll;
}
.modal_page .fixed_clobtn{
  position: fixed;
  width: 100%;
  padding: 1.6rem 2rem;
  text-align: right;
  background-color: white;
  cursor: pointer;
}

.toast_message{
  padding: 1.6rem 2rem;
  border-radius: 5rem;
  background: rgba(0, 0, 0, 0.70);
  color: white;
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; 
  width: 14.3rem;
  letter-spacing: -0.032rem;
  position: fixed;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.5s;
  z-index: 999;
  user-select: none;
}

.toast_message.active{
  opacity: 1;
}

.prograss_text{
  display: inline-block;
  background: linear-gradient(244deg, #3E66FF 13.11%, #C850FF 84.47%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.donut-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 11.4rem;
  height: 11.4rem;
}

.donut-text {
  position: absolute;
  font-size: 2.2rem;
  font-weight: 700;
  color: #6B5FFF;
}
.donut-text > span{
  font-size: 1.6rem;
}
.donut {
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;
}

.donut-segment {
  transition: stroke-dashoffset 0.3s;
  stroke-linecap: round;
}
.modal_wrap{
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.40);
  height: 100%;
  z-index: 9999;
}
.donut-wrap{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.8rem;
  background: white;
  width: 30rem;
  height: 28rem;
  border-radius: 0.8rem;
}
.donut-wrap .caption{
  color: var(--grayscale-600) ;
}
.fail_modal_wrap{
  z-index: 9999;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.40);
}
.fail_modal{
  text-align: center;
  padding: 1.6rem 2.4rem;
  width: 32rem;
  background-color: white;
  border-radius: 1.4rem ;
}
.fail_modal .small02{
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}
.fail_btn_wrap{
  display: flex;
  gap: 0.8rem;
}
.fail_btn_wrap > .cancel_btn{
  width: 10rem;
}
.fail_btn_wrap > .re_camara_btn{
  width: 16.4rem;
}
.camara_wrap{
  position: relative;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 360 / 480;
}
/* .camara_wrap{
  position: relative;
  width: 100%;
  height: calc(100vh - 5.6rem);
  overflow: hidden;
} */
.camara_wrap > video{
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
.camara_wrap > canvas{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0 ;
  text-align: "center";
}
.condition_wrap{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 999;
}
.condition_wrap > .condition_box{
  padding: 1rem 0;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem ;
  color: white;
  position: relative;
}
.condition_wrap > .state_box{
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  height: 4.8rem;
  line-height: 4.8rem;
  text-align: center;
  width: 100%;
  color: white;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
}
.condition_box .con_title{
  display: flex;
  align-items: center;
  gap: 0.3rem;
  line-height: 1rem;
}
.condition_box .label01{
  line-height: 1rem;
  vertical-align: middle ;
  transform: translateY(-0.1rem);
}
.condition_wrap > .condition_box + .condition_box::after{
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 2px;
    background-color: #fff;
    left:  0;
    top: 50%;
    transform: translate(-50%,-50%);
}
.condition_wrap > .condition_box.nomal{
  background-color: var(--grayscale-500);
}
.condition_wrap > .condition_box.nomal > .con_state::after{
  content: "감지 중";
}
.condition_wrap > .condition_box.bad{
  background-color: var(--secondary-red) ;
}
.condition_wrap > .condition_box.bad > .con_state::after{
  content: "나쁨";
}
.condition_wrap > .condition_box.warning{
  background-color: var(--secondary-yellow) ;
}
.condition_wrap > .condition_box.warning > .con_state::after{
  content: "경고";
}
.condition_wrap > .condition_box.good{
  background-color: var(--primary-700) ;
}
.condition_wrap > .condition_box.good > .con_state::after{
  content: "좋음";
}

.camara_section .small02{
  color: var(--primary-700);
  text-align: center;
}
.camara_guide_box{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  /* top: 0;
  left: 0;
  transform: translateY(-11rem); */
}
.camara_guide_box::after{
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: #0000004D;
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-0.25rem);
}
.camara_guide_box .guide_back svg{
  width: 100%;
}
.camara_guide_box .guide_face{
  z-index: 9;
}
.camara_guide_box .guide_state_warning,
.camara_guide_box .guide_state_good,
.camara_guide_box .guide_face{
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%,-50%);
  opacity: 0;
}
.camara_guide_box .guide_state_warning.active,
.camara_guide_box .guide_state_good.active,
.camara_guide_box .guide_face.active,
.camara_guide_box .guide_face.animation{
  opacity: 1 !important;
}
.camara_guide_box .guide_face.animation > svg{
  animation-name: scale_animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes scale_animation {
  0%{
    transform: scale(1);
  }
  100%{
    transform: scale(1.08);
  }
}

.camara_btn_wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 2.7rem;
}
.camara_btn_wrap .refresh_btn{
  position: absolute;
  right: 3.6rem;
}

.page_dissolve_in{
  opacity: 1 ;
  transition: cubic-bezier(0.42, 0, 0.58, 1) 300ms ;
}
.page_dissolve_out{
  opacity: 0 ;
  transition: cubic-bezier(0.42, 0, 0.58, 1) 300ms ;
}
.survey_form_wrap{
  opacity: 0;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: cubic-bezier(0.42, 0, 0.58, 1) 300ms ;
}
.fade-exit {
  opacity: 0;
}
.fade-exit-active {
  opacity: 1;
  transition: cubic-bezier(0.42, 0, 0.58, 1) 300ms ;
}
.data_send_loading_wrap{
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000066;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.data_send_loading > img{
  width: 8rem;
}

.detail-enter {
  opacity: 0;
  transform: translateY(100%);
}
.detail-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity cubic-bezier(0.42, 0, 0.58, 1) 400ms, transform cubic-bezier(0.42, 0, 0.58, 1) 400ms;
}
.detail-exit {
  opacity: 1;
}
.detail-exit-active {
  opacity: 0;
  transform: translateY(100%);
  transition: cubic-bezier(0.42, 0, 0.58, 1) 400ms, transform cubic-bezier(0.42, 0, 0.58, 1) 400ms;
}
/* 
.camara_under.over{
  position: absolute;
  width: 100%;
  bottom: -7rem;
} 
.camara_under.over .small02{
  color: white;
  margin-top: 0;
}
.camara_under.over .camara_btn svg{
  width: 5rem !important;
  height: 7rem !important;
}
.camara_under.over .camara_btn_wrap{
  margin-top: 2rem;
}  
*/

.camara_under{
  display: none;
}
.camara_section{
  position: relative;
}
.capture_confirm_wrap{
  position: absolute;
  z-index: 99999;
  width: 100%;
  height: calc(100vh - 5.6rem);
  left: 0;
  top: 0;
  background-color: white;
}
.capture_confirm_box{
  text-align: center;
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.capture_confirm_wrap img{
  border-radius: 1.2rem;
  width: 30rem;
  aspect-ratio: 3 / 4;
  object-fit: cover;
  object-position: center;
  margin: 0 auto;
}
.capture_confirm_wrap .large01{
  margin-top: 1.8rem;
  margin-bottom: 2rem;
}
.survey_btn_box{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 6.4rem;
  margin-top: 4rem;
}
.survey_btn_box img{
  width: 6.4rem;
}

.camera_loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10rem; /* 100px */
  height: 10rem; /* 100px */
  opacity:0;
}

.camera_loading .number {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.4rem; /* 24px */
  color: white;
}

.camera_loading svg {
  width: 10rem; /* 100px */
  height: 10rem; /* 100px */
  position: absolute;
  top: 0;
  left: 0;
}

.camera_loading .background-circle {
  fill: none;
  stroke: rgba(255, 255, 255, 0.5);
  stroke-width: 1rem; /* 10px */
}

.camera_loading .progress-circle {
  fill: none;
  stroke: rgba(255, 255, 255, 0.8);
  stroke-width: 1rem; /* 10px */
  stroke-dasharray: 28.2rem; /* 2 * Math.PI * 4.5rem (45px) */
  stroke-dashoffset: 28.2rem;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-linecap: round;
}

.camera_loading.active{
  opacity: 1;
  z-index: 9999;
}
.camera_loading.active .progress-circle {
  animation: fill 2s linear forwards reverse;
}

.camera_loading.active .number::before {
  display: block;
  animation: count 2s steps(3) forwards reverse;
  content: "3";
}

@keyframes fill {
  to {
      stroke-dashoffset: 0;
  }
}

@keyframes count {
  0%, 33% {
      content: "1";
  }
  34%, 66% {
      content: "2";
  }
  67%, 100% {
      content: "3";
  }
}
.up_prepare{
  transform: translateY(100vh);
  transition: 300ms;
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1 );
}
.up_prepare.up_active{
  transform: translateY(0);
}
.pdf_influencer_img{
  display: block !important;
  width: 90% !important;
  margin: 3rem auto;
}
.pdf_influencer_img .product_img > img{
  width: 100%;
}

.pdf_influencer_img .product_info > div{
  width: 50%;
}
.pdf_similar_img {
  margin-top: 2.9rem;
  padding-right: 5.5rem;
  padding-left: 5.5rem;
}
.pdf_similar_img img{
  width: 100%;
}
.pdf_user_data_wrap{
  width: 100%;
  text-align: center;
  margin-top: 3.2rem;
  display: flex;
  flex-wrap: wrap;
}
.pdf_user_data_box{
  width: 50%;
  padding-left: 0.4rem;
  padding-right: 0.4rem;
  margin-bottom: 1rem;
}
.pdf_user_data_wrap .data_label_box{
  display: inline-block;
  color: var(--primary-700) ;
  padding: 0.9rem;
  text-align: center;
  background-color: var(--grayscale-100);
  border-radius: 0.5rem;
}
.data_img_box{
  position: relative;
  width: 100%;
  margin-top: 1rem;
}
.data_img_box > img{
  display: block;
  width: 100%;
  position: relative;
  z-index: 99;
}
.img_empty_overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  background-position: center;
  background-size: contain;
  background-image: url("../public/img/sample_profile_img.png");
  background-repeat: no-repeat;
  z-index: 2;
}
.pdf_recom_data_wrap{
  margin-bottom: 4rem;
}
.pdf_recom_data_wrap .title_box{
  margin-bottom: 3.2rem;
  margin-top: 4rem;
}
.pdf_recom_data_wrap .pdf_recom_type_title{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.pdf_recom_type_title img{
  margin-right: 0.5rem;
  filter: invert(34%) sepia(75%) saturate(3448%) hue-rotate(232deg) brightness(106%) contrast(101%);
}
.pdf_recom_product{
  margin-top: 1.2rem;
  display: flex;

}
.recom_type{
  margin-top: 1.6rem;
}
.pdf_recom_product img{
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  object-position: center;
}
.pdf_recom_product{
  display: flex;
  gap: 1.6rem;
  align-items: center;
}
.pdf_pro_info{
  display: flex;
  gap: 1.4rem;
  flex-direction: column;
}
.pdf_pro_info div{
  line-height: 1;
}
.pdf_pro_info .label02{
  color: var(--grayscale-600);
}
.pdf_pro_info .pdf_pro_price{
  color: var(--primary-700);
}
.recom_product_tap_box {
  display: none;
}
.recom_product_box{
  display: none;
}